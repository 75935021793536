body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,code,form,fieldset,legend,input,textarea,p,blockquote,th,td{margin:0;padding:0;}table{border-collapse:collapse;border-spacing:0;}fieldset,img{border:0;}address,caption,cite,code,dfn,em,strong,th,var{font-style:normal;font-weight:normal;}li{list-style:none;}caption,th{text-align:left;}h1,h2,h3,h4,h5,h6{font-size:100%;font-weight:normal;}q:before,q:after{content:'';}abbr,acronym{border:0;font-variant:normal;}sup{vertical-align:text-top;}sub{vertical-align:text-bottom;}input,textarea,select{font-family:inherit;font-size:inherit;font-weight:inherit;}input,textarea,select{font-size:100%;}legend{color:#000;}a{text-decoration:none;}

@font-face {
	font-family: SKModernist;
	font-weight: normal;
	src: url(/assets/sk-modernist-regular-webfont.eot);
	src: url(/assets/sk-modernist-regular-webfont.eot?#iefix) format('embedded-opentype'),
	    url(/assets/sk-modernist-regular-webfont.svg#SKModernist) format('svg');
}

@font-face {
	font-family: SKModernist;
	font-weight: bold;
	src: url(/assets/sk-modernist-bold-webfont.eot);
	src: url(/assets/sk-modernist-bold-webfont.eot?#iefix) format('embedded-opentype'),
		url(/assets/sk-modernist-bold-webfont.svg#SKModernist) format('svg');
}

body {
	font-family: SKModernist,sans-serif;
	font-size: 16px;
}

strong {
	font-weight: 700;
}

nav {
	align-items: center;
	background-color: #fff;
	box-shadow: 0 2px 10px rgba(0,0,0,0.2);
	display: flex;
	justify-content: space-between;
	left: 0;
	padding: 1rem;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 10;
}

nav > div {
	white-space: nowrap;
}

nav svg {
	vertical-align: middle;
}

nav .vama-logo-txt {
	margin-left: 1rem;
}

nav .vama-nav-link {
	color: #5c5c5c;
	font-size: 1.125rem;
	font-weight: 500;
	margin-right: 3rem;
	text-decoration: none;
}

nav .vama-nav-btn {
	background-color: #89e83f;
	border-radius: 0.5rem;
	color: #fff;
	display: inline-block;
	font-size: 1rem;
	line-height: 1;
	padding: 1.25rem 2rem;
	text-transform: uppercase;
	white-space: nowrap;
}

footer {
	background-color: #fff;
	padding: 1rem;
	text-align: center;
}

footer ul li {
	display: inline-block;
	line-height: 1;
	margin: 0 1rem 0.5rem;
}

footer ul li img {
	height: 24px;
	width: 24px;
}

footer p {
	color: #5c5c5c;
	font-size: 1.125rem;
	font-weight: 500;
	line-height: 1.25;
}

footer a {
	color: inherit;
	text-decoration: none;
}

.vama-audio-player-wrap {
	position: relative;
}

.vama-audio-player {
	align-items: center;
	background-color: #fff;
	border: 0.375rem solid #000;
	border-radius: 1rem;
	box-sizing: border-box;
	display: flex;
	justify-content: space-between;
	outline: none;
	padding: 1.5rem 2rem;
	user-select: none;
	width: 100%;
}

.vama-question-playback {
	align-items: center;
	box-sizing: border-box;
	display: grid;
	grid-column-gap: 1rem;
	grid-template-columns: 4.375rem 1fr 3.375rem;
	outline: none;
	padding: 2rem;
	user-select: none;
	width: 100%;
}

.vama-question-playback > div {
	position: relative;
}

.vama-question-playback.answered > div::before {
	border: 1px solid #3d7bf3;
	border-radius: 0.5rem;
	color: #3d7bf3;
	content: 'ANSWERED';
	display: inline-block;
	font-size: 0.625rem;
	padding: 0.15rem 0.75rem;
	position: absolute;
	top: -1.5rem;
}

.vama-question-playback.rerecordable .vama-rerecord-link {
	color: #ff8126;
	font-size: 0.625rem;
	position: absolute;
	top: -1rem;
}

#vama-preview-all-responses .vama-audio-player,
#vama-share-player .vama-audio-player {
	border: none;
	border-radius: 0;
}

#vama-share-player .vama-audio-player {
	margin: 2rem auto;
	max-width: 570px;
	padding: 0;
}

#vama-share-player .vama-audio-player + .vama-audio-player {
	max-width: 400px;
}

#vama-share-player .vama-audio-player.fixed {
	background-color: #f4f4f4;
	bottom: 0;
	left: 0;
	margin: 0;
	max-width: 100%;
	padding: 0.5rem 1.5rem;
	position: fixed;
	right: 0;
}

.vama-player-btn {
	border: none;
	border-radius: 50%;
	cursor: pointer;
	outline: none;
}

.vama-player-btn:disabled {
	cursor: default;
	opacity: 0.2;
}

.vama-audio-player .vama-current-time,
.vama-audio-player .vama-total-time {
	color: #000;
	font-family: Questrial,sans-serif;
	font-size: 1.5rem;
	line-height: 1;
}

.vama-audio-player .vama-player-slider {
	background-color: #c4c4c4;
	border-radius: 0.125rem;
	flex-grow: 1;
	height: 0.25rem;
	margin: 0 1.875rem;
	position: relative;
}

.vama-audio-player .vama-player-slider-pin {
	border-radius: 0.75rem;
	height: 1.5rem;
	left: -0.75rem;
	pointer-events: all;
	position: absolute;
	top: -0.625rem;
	width: 1.5rem;
}

.vama-question-playback .vama-heart-btn,
#vama-share-player .vama-heart-btn {
	background-image: url(/assets/heart-unliked.svg);
	background-position: top center;
	background-repeat: no-repeat;
	color: #c4c4c4;
	font-family: Questrial,sans-serif;
	font-size: 1.25rem;
	padding: 3rem 0 0;
	text-align: center;
}

.vama-question-playback .vama-heart-btn.liked,
#vama-share-player .vama-heart-btn.liked {
	background-image: url(/assets/heart-liked.svg);
	color: #fd73a4;
}

#vama-share-player .vama-heart-btn {
	background-size: 100% auto;
	font-size: 1rem;
	margin-top: 0.15rem;
	padding: 1.5rem 0 0;
}

.vama-question-playback ul li {
	color: #1a25a3;
	display: inline-block;
	font-size: 1.5rem;
}

.vama-question-playback ul li a {
	color: inherit;
}

.vama-question-playback ul li + li {
	margin-left: 0.5rem;
}

.vama-record-tip {
	left: -7rem;
	margin-top: -3rem;
	position: absolute;
}

#vama-preview .vama-record-tip {
	left: -6.5rem;
	margin-top: -3rem;
	position: absolute;
}

#vama-eye-tip {
	left: -17rem;
	position: absolute;
	top: -8rem;
}

.vama-record-warning {
	color: #9375a7;
	font-size: 1.5rem;
	margin: 0.5rem 0 2rem;
}

#vama-home header,
#vama-create header,
#vama-preview header,
#vama-share header {
	padding: 8.5rem 5% 4rem;
	text-align: center;
}

#vama-home header,
#vama-what-is-vama {
	background-color: #dceffe;
}

#vama-home header img {
	display: block;
	margin: 0 auto 1.5rem;
	max-width: 100%;
}

#vama-create header h1,
#vama-preview header h1,
#vama-share header h1 {
	font-size: 3.1875rem;
	font-weight: 400;
	line-height: 1.25;
	white-space: pre-line;
}

#vama-create header p {
	color: #343434;
	font-size: 1.375rem;
	margin: 1.5rem auto;
	max-width: 430px;
}

#vama-what-is-vama,
#vama-create-main,
#vama-preview-main {
	background-color: #dceffe;
	padding: 5%;
}

#vama-what-is-vama > div,
#vama-create-form,
#vama-create-details,
#vama-record-response > div,
#vama-record-submitted > div,
#vama-preview-responses,
#vama-submitted-questions {
	margin: 0 auto;
	max-width: 680px;
	position: relative;
}

#vama-record-submitted > div {
	max-width: 850px;
}

#vama-what-is-vama svg {
	height: auto;
	margin: 1rem auto 1.5rem;
	max-width: 45%;
}

#vama-what-is-vama dt {
	font-size: 3rem;
	line-height: 1.2;
}

#vama-what-is-vama dd {
	font-size: 1.375rem;
	margin-top: 1rem;
}

#vama-what-is-vama dd p {
	margin-bottom: 1.5rem;
}

#vama-about-text > * {
	font-size: 1.375rem;
	margin: 1rem auto;
	max-width: 650px;
}

#vama-about-text h3 {
	font-weight: 700;
}

#vama-home-examples {
	background-color: #ace1ff;
	padding: 5% 0;
}

#vama-home-how-works h2,
#vama-home-ways-use h2,
#vama-create-form h2,
#vama-create-details h2,
#vama-preview-responses h2 {
	font-size: 3rem;
	font-weight: 700;
	line-height: 1.2;
	margin-bottom: 4%;
}

#vama-home-examples h2,
#vama-record-response h2,
#vama-record-phone h2,
#vama-record-what-next h2,
#vama-submitted-questions h2{
	font-size: 3rem;
	font-weight: 700;
	line-height: 1.2;
}

#vama-record-response form h2 {
	margin-bottom: 2rem;
}

#vama-record-what-next h2 {
	font-size: 2.75rem;
}

#vama-home-how-works h2,
#vama-home-examples h2 {
	font-size: 4rem;
}

h2 .vama-info-icon {
	cursor: pointer;
	vertical-align: super;
}

#vama-create-form form {
	overflow: hidden;
}

#vama-create-form label,
#vama-create-details label,
#vama-record-response label {
	font-size: 1.875rem;
	font-weight: 700;
}

#vama-create-form label em {
	color: #5c5c5c;
}

#vama-create-form p {
	font-size: 1rem;
	margin: 0.25rem 0 1rem;
}

#vama-create-form table {
	width: 100%;
}

#vama-create-form table td {
	text-align: center;
	vertical-align: bottom;
	width: 50%;
}

#vama-create-form input,
#vama-record-response input,
#vama-create-form textarea,
#vama-create-details textarea,
#vama-record-response textarea {
	background-color: #fff;
	border: 0.375rem solid #000;
	border-radius: 0.5rem;
	box-sizing: border-box;
	display: block;
	font-size: 1.25rem;
	margin: 1rem 0 1.5rem;
	max-width: 100%;
	width: 100%;
}

#vama-create-form input,
#vama-record-response input {
	padding: 3%;
}

#vama-record-response #vama-record-tags {
	color: #1a25a3;
}

#vama-create-form textarea,
#vama-record-response textarea {
	height: 8.5rem;
	min-height: 8.5rem;
	padding: 3%;
}

#vama-create-details textarea {
	height: 16.75rem;
	min-height: 16.75rem;
	padding: 3% 2%;
}

#vama-create-form input[type=radio] {
	display: none;
}

#vama-create-form input[type=radio] + label {
	color: #5c5c5c;
	white-space: nowrap;
}

#vama-create-form input[type=radio]:checked + label {
	color: #000;
}

#vama-create-form input[type=radio] + label::after {
	background-color: #fff;
	border: 0.375rem solid #5c5c5c;
	border-radius: 50%;
	content: '';
	cursor: pointer;
	display: block;
	height: 1rem;
	margin: 0.5rem auto 1.75rem;
	width: 1rem;
}

#vama-create-form input[type=radio]:checked + label::after {
	background: radial-gradient(#000 0%,#000 40%,#fff 50%,transparent);
	border-color: #000;
}

.vama-submit-btn {
	border: none;
	border-radius: 0.5rem;
	color: #fff;
	cursor: pointer;
	font-size: 1.75rem;
	font-weight: 700;
	line-height: 1;
	padding: 2rem 0;
	text-align: center;
	text-transform: uppercase;
	white-space: nowrap;
	width: 100%;
}

.vama-submit-btn:disabled {
	cursor: default;
	opacity: 0.2;
}

#vama-home header .vama-create-btn,
#vama-create-form .vama-submit-btn,
#vama-footer-create .vama-create-btn {
	background-color: #fd73a4;
}

#vama-create-details .vama-submit-btn {
	background-color: #5c93ff;
}

#vama-preview-main .vama-submit-btn,
#vama-record-response .vama-submit-btn {
	background-color: #a038e0;
}

#vama-preview-publish .vama-submit-btn {
	background-color: #3d7bf3;
}

#vama-preview-all-responses .vama-submit-btn {
	background-color: #7bcf3a;
	margin: 0 2rem 1.5rem;
	width: calc(100% - 4rem);
}

.vama-confirm-btn {
	background-color: #3d7bf3;
	border-radius: 0.5rem;
	color: #fff;
	display: block;
	font-size: 1.75rem;
	font-weight: 700;
	line-height: 1;
	margin: 2rem auto 0;
	padding: 2rem 0;
	text-align: center;
	text-transform: uppercase;
	white-space: nowrap;
	width: 100%;
}

.vama-create-btn {
	color: #fff;
	cursor: pointer;
	border-radius: 0.5rem;
	box-sizing: border-box;
	display: inline-block;
	font-size: 1rem;
	font-weight: normal;
	line-height: 1;
	max-width: 500px;
	padding: 1.25rem 2rem;
	text-align: center;
	text-transform: uppercase;
	white-space: nowrap;
	width: 100%;
}

#vama-home header .vama-create-btn {
	max-width: 675px;
}
.vama-show-btn {
	background-color: #cc79ff;
	border-radius: 0.5rem;
	color: #fff;
	display: inline-block;
	margin: 0 auto 1.5rem;
	font-size: 0.875rem;
	padding: 1rem;
	white-space: nowrap;
}

.vama-text-btn {
	background: none;
	border: 3px solid #000;
	border-radius: 1.2rem;
	color: #000;
	cursor: pointer;
	display: block;
	font-size: 1rem;
	font-weight: 700;
	line-height: 1.2;
	padding: 0.5rem 3.25rem;
	text-align: center;
	text-transform: uppercase;
	white-space: nowrap;
}

#vama-record-response ol + .vama-text-btn {
	margin: 0 auto 1rem;
	max-width: 200px;
}

.vama-cancel-link {
	color: #979797;
	display: inline-block;
	font-size: 1.75rem;
	font-weight: 700;
	margin: 2rem auto 0;
	text-transform: uppercase;
}

#vama-create-player {
	margin: 1rem 0 2rem;
}

#vama-create .vama-player-btn,
#vama-preview .vama-player-btn,
#vama-record .vama-player-btn,
#vama-share .vama-player-btn {
	align-items: center;
	display: flex;
	flex: 0 0 3.375rem;
	height: 3.375rem;
	justify-content: center;
	width: 3.375rem;
}

#vama-create .vama-player-btn,
#vama-create .vama-player-slider-pin {
	background-color: #fd73a4;
}

#vama-preview .vama-player-btn,
#vama-record .vama-player-btn,
#vama-preview .vama-player-slider-pin,
#vama-record .vama-player-slider-pin,
#vama-preview-all-responses .vama-record-response .vama-player-btn,
#vama-preview-all-responses .vama-record-response .vama-player-slider-pin {
	background-color: #cc79ff;
}

#vama-record .vama-question-playback .vama-player-btn {
	background-color: #abea7b;
	height: 4.375rem;
	width: 4.375rem;
}

#vama-record-listen-overlay .vama-player-btn,
#vama-record-listen-overlay .vama-player-slider-pin {
	background-color: #91ed49;
}

#vama-preview-all-responses .vama-player-btn,
#vama-preview-all-responses .vama-player-slider-pin {
	background-color: #83adff;
}

#vama-share-player .vama-player-btn,
#vama-share-player .vama-player-slider-pin {
	background-color: #c174f2;
}

#vama-share-player .vama-prev-answer-btn,
#vama-share-player .vama-next-answer-btn {
	background-color: transparent;
	flex: 0 0 0.75rem !important;
	height: 0.75rem !important;
	width: 0.75rem !important;
}

.vama-audio-player .play-pause-btn__icon {
	height: 2rem;
	width: 2rem;
}

#vama-create .vama-record-btn,
#vama-preview .vama-record-btn,
#vama-record .vama-record-btn,
#vama-create .vama-playback-btn,
#vama-preview .vama-playback-btn,
#vama-record .vama-playback-btn {
	margin-right: 1rem;
}

#vama-preview-all-responses .vama-respond-btn {
	background-color: #abea7b;
}

#vama-preview-all-responses .vama-delete-btn {
	background-color: #ff9f9f;
	margin-left: 0.75rem;
}

#vama-preview-all-responses .vama-download-btn,
#vama-preview-all-responses .vama-copy-btn {
	background-color: #bfceeb;
	margin-left: 0.75rem;
}

#vama-preview-all-responses .vama-record-response .vama-rewind-btn {
	margin-left: 0.75rem;
	overflow: hidden;
}

#vama-preview-all-responses .vama-record-response {
	padding-top: 3rem;
	position: relative;
}

#vama-preview-all-responses .vama-record-response p {
	font-size: 1.25rem;
	position: absolute;
	top: 0;
}

#vama-preview-all-responses .vama-record-response p small {
	font-size: 1rem;
}

#vama-preview-all-responses .response-recorded dl:first-of-type dd::after {
	content: url(/assets/check-recorded.svg);
	margin-left: 0.75rem;
	vertical-align: middle;
}

#vama-home-how-works,
#vama-home-ways-use {
	padding: 5%;
	overflow: hidden;
}

#vama-home-how-works {
	background-color: #eccdff;
}

#vama-home-ways-use > div {
	display: grid;
	grid-column-gap: 2rem;
	grid-row-gap: 2rem;
	grid-template-columns: repeat(3,1fr);
	margin: 1rem auto;
	max-width: 675px;
}

#vama-home-how-works h2,
#vama-home-examples h2,
#vama-home-ways-use dl,
#vama-home-ways-use h2 {
	text-align: center;
}

#vama-home-ways-use dl svg {
	max-width: 100%;
}

#vama-home-ways-use dl dd {
	color: #5c5c5c;
	font-size: 1.125rem;
	margin: 8% 0 8% 10%;
}

#vama-create-details ul {
	font-size: 1.5rem;
	margin: 8% 3% 0;
}

#vama-create-details ul li {
	align-items: center;
	background-position: left center;
	background-repeat: no-repeat;
	color: #343434;
	display: flex;
	margin: 2rem 0;
	min-height: 5rem;
	padding-left: 5rem;
}

#vama-create-details ul li:first-of-type {
	background-image: url(/assets/details1.svg);
}

#vama-create-details ul li:nth-of-type(2) {
	background-image: url(/assets/details2.svg);
}

#vama-create-faq {
	background-color: #e3fff3;
	color: #343434;
	padding: 5%;
}

#vama-create-faq h3 {
	color: inherit;
	font-size: 2.5rem;
	font-weight: 700;
	line-height: 1.2;
	margin-bottom: 1rem;
}

#vama-create-faq p {
	font-size: 1.375rem;
	margin-bottom: 1.5rem;
}

#vama-record-response {
	background-color: #e6bdff;
	margin-top: 5.5rem;
	padding: 1% 5% 5%;
}

#vama-submitted-questions {
	padding-top: 8%;
	text-align: center;
}

#vama-all-submitted-questions {
	text-align: left;
}

#vama-record-phone {
	background-color: #c0ecff;
	padding: 5%;
}

#vama-record-what-next {
	background-color: #ffe386;
	padding: 5%;
}

#vama-record-phone > div,
#vama-record-what-next > div {
	margin: 0 auto;
	max-width: 750px;
}

#vama-record-response h1 {
	color: #800dc6;
	font-size: 3.5rem;
	line-height: 1.1;
	margin-bottom: 1rem;
	position: relative;
	text-align: center;
	z-index: 1;
}

#vama-record-response h1 strong {
	font-weight: 700;
}

#vama-record-response table {
	margin-bottom: 1rem;
}

#vama-record-response table td + td {
	padding-left: 1rem;
}

#vama-record-response input[type=radio] {
	display: none;
}

#vama-record-response input[type=radio] + label {
	color: #5c5c5c;
	white-space: nowrap;
}

#vama-record-response input[type=radio]:checked + label {
	color: #000;
}

#vama-record-response input[type=radio] + label::before {
	background-color: #fff;
	border: 0.375rem solid #5c5c5c;
	border-radius: 50%;
	content: '';
	cursor: pointer;
	display: inline-block;
	height: 1rem;
	margin: 0 0.5rem -0.25rem;
	width: 1rem;
}

#vama-record-response input[type=radio]:checked + label::before {
	background: radial-gradient(#000 0%,#000 40%,#fff 50%,transparent);
	border-color: #000;
}

#vama-record-phone h3 {
	color: #353535;
	font-size: 1.75rem;
	font-weight: 700;
}

#vama-record-response > div > div:first-of-type p,
#vama-record-phone p,
#vama-record-what-next p {
	color: #353535;
	font-size: 1.375rem;
	margin: 1rem auto 2rem;
}

#vama-record-phone h3 + p {
	margin: 0 auto 0.5rem;
}

#vama-record-phone p em {
	color: #ff89b3;
}

#vama-record-submitted {
	text-align: center;
}

#vama-record-submitted h3 {
	clear: both;
	font-size: 2.625rem;
	font-weight: 700;
	line-height: 1.2;
}

#vama-record-submitted h4 {
	font-size: 1.875rem;
	font-weight: 700;
	line-height: 1.2;
}

#vama-record-submitted p,
#vama-submitted-questions p {
	color: #5c5c5c;
	font-size: 1.75rem;
	margin: 1rem auto 2rem;
}

#vama-record-response #vama-record-submitted .vama-text-btn {
	margin: 0 auto;
}

#vama-preview header {
	margin-bottom: 1.5rem;
}

#vama-preview header p {
	color: #999;
	font-size: 1.5rem;
	padding-top: 1rem;
	text-align: center;
}

#vama-preview-main ul,
#vama-record-response ol {
	margin-bottom: 6%;
	position: relative;
}

#vama-home-how-works ul li,
#vama-preview-main ul li,
#vama-record-response ol li {
	align-items: center;
	background-position: left top;
	background-repeat: no-repeat;
	background-size: 73px 56px;
	display: flex;
	line-height: 1.25;
	min-height: 4rem;
	padding-left: 5.5rem;
}

#vama-home-how-works ul li:first-of-type {
	background-image: url(/assets/setup.svg);
}

#vama-home-how-works ul li:nth-of-type(2) {
	background-image: url(/assets/collect.svg);
}

#vama-home-how-works ul li:nth-of-type(3) {
	background-image: url(/assets/preview.svg);
}

#vama-home-how-works ul li:nth-of-type(4) {
	background-image: url(/assets/record.svg);
}

#vama-home-how-works ul li:nth-of-type(5) {
	background-image: url(/assets/share.svg);
}

#vama-preview-main ul li:first-of-type {
	background-image: url(/assets/view.svg);
}

#vama-preview-main ul li:nth-of-type(2) {
	background-image: url(/assets/answer.svg);
}

#vama-preview-main ul li:nth-of-type(3) {
	background-image: url(/assets/delete.svg);
}

#vama-preview-main ul li:nth-of-type(4) {
	background-image: url(/assets/publish.svg);
}

#vama-record-response ol li:first-of-type {
	background-image: url(/assets/recordquestion.svg);
}

#vama-record-response ol li:nth-of-type(2) {
	background-image: url(/assets/upvotequestion.svg);
}

#vama-record-response ol li:nth-of-type(3) {
	background-image: url(/assets/relatedquestion.svg);
}

#vama-preview-responses > div,
#vama-submitted-questions > div {
	background-color: #fff;
	border: 0.375rem solid #000;
	border-radius: 1rem;
}

#vama-preview-no-responses {
	color: #515151;
	margin: 25% auto;
	max-width: 372px;
}

#vama-preview-no-responses h3 {
	font-size: 2.875rem;
}

#vama-preview-no-responses p {
	font-size: 1.5rem;
}

#vama-preview-no-responses p a {
	color: #3d7bf3;
	text-decoration: underline;
}

#vama-preview-all-responses > div + div::before,
#vama-all-submitted-questions > div + div::before {
	content: '';
	display: block;
	border-top: 2px solid #c4c4c4;
	margin: 0 auto;
	width: 90%;
}

#vama-preview-all-responses dl:first-of-type {
	font-size: 1.75rem;
	padding: 1.5rem 2rem 0;
}

#vama-all-submitted-questions .vama-question-playback dl {
	font-size: 1.5rem;
}

#vama-all-submitted-questions .vama-question-playback.answered dl {
	color: #b0b0b0;
}

#vama-preview-all-responses dl:first-of-type dt,
#vama-all-submitted-questions .vama-question-playback dl dt {
	display: inline;
	font-weight: 700;
}

#vama-preview-all-responses dl:first-of-type dd,
#vama-all-submitted-questions .vama-question-playback dl dd {
	display: inline;
}

#vama-preview-all-responses dl:first-of-type dd::before,
#vama-all-submitted-questions .vama-question-playback dl dd::before {
	content: '\2002 | \2002';
}

dl.vama-transcription,
.vama-question-playback + dl {
	font-size: 1.5rem;
	padding: 0 2rem 1.5rem;
}

dl.vama-transcription dd,
.vama-question-playback + dl dd {
	color: #5c5c5c;
	font-size: 1.375rem;
	margin-top: 1rem;
}

dl.vama-transcription dd {
	align-items: flex-start;
	display: flex;
	justify-content: space-between;
}

dl.vama-transcription,
dl.vama-transcription dd {
	font-size: 1.25rem;
}

dl.vama-transcription dt span {
	cursor: pointer;
}

dl.vama-transcription dt span::after {
	content: url(/assets/caret.svg);
	display: inline-block;
	line-height: 0.625rem;
	margin-left: 0.75rem;
}

dl.vama-transcription.inactive dt span::after {
	transform: rotate(180deg);
}

dl.vama-transcription.inactive dd {
	display: none;
}

#vama-preview-all-responses .vama-like-count {
	color: #c4c4c4;
	float: right;
	font-size: 1.125rem;
}

#vama-preview-all-responses .vama-like-count::before {
	content: url(/assets/heart-count.svg);
	display: inline-block;
	margin: 0 0.25rem;
	vertical-align: middle;
}

#vama-preview-all-responses .vama-responders {
	background: #ffc14a url(/assets/icon-responders.svg) center center no-repeat;
	border-radius: 50%;
	cursor: pointer;
	display: inline-block;
	height: 2.375rem;
	margin-left: 1rem;
	position: relative;
	vertical-align: text-bottom;
	width: 2.375rem;
}

#vama-preview-all-responses .vama-responders-count {
	background-color: #000;
	border-radius: 50%;
	color: #fff;
	cursor: pointer;
	font-size: 0.75rem;
	height: 1.25rem;
	line-height: 1.625;
	position: absolute;
	right: -0.4rem;
	text-align: center;
	top: -0.4rem;
	width: 1.25rem;
}

#vama-preview-all-responses .vama-responders-list li:first-of-type::before {
	color: #000;
	content: 'Responders';
	display: block;
	font-size: 1.125rem;
	font-weight: 700;
	margin-bottom: 0.25rem;
}

#vama-preview-all-responses .vama-responders-list {
	background-color: #fff;
	border: 2px solid #000;
	border-radius: 1rem;
	bottom: 60px;
	color: #5c5c5c;
	cursor: default;
	display: none;
	font-size: 1rem;
	left: -60px;
	padding: 1.125rem;
	position: absolute;
	width: 120px;
}

#vama-preview-all-responses .vama-responders-list::before,
#vama-preview-all-responses .vama-responders-list::after {
	border-left: 1rem solid transparent;
	border-right: 1rem solid transparent;
	content: '';
	left: 50%;
	margin-left: -1rem;
	position: absolute;
	top: 100%;
}

#vama-preview-all-responses .vama-responders-list::before {
	border-top: 1rem solid #000;
}

#vama-preview-all-responses .vama-responders-list::after {
	border-top: 1rem solid #fff;
	margin-top: -2px;
	z-index: 1;
}

#vama-preview-all-responses .vama-responders:hover .vama-responders-list {
	display: block;
}

#vama-preview-all-responses .vama-response-contact-form {
	display: grid;
	font-size: 0.875rem;
	grid-column-gap: 1.25rem;
	grid-template-columns: 1fr 1fr;
	padding: 0 2rem 1.5rem;
}

#vama-preview-all-responses .vama-response-contact-form label {
	display: block;
	font-weight: 700;
	margin-bottom: 0.5rem;
}

#vama-preview-all-responses .vama-response-contact-form input {
	border: 2px solid #000;
	border-radius: 0.5rem;
	box-sizing: border-box;
	padding: 1rem;
	width: 100%;
}

.vama-preview-delete-response {
	font-size: 1.25rem;
	padding: 1.5rem 2rem;
}

.vama-preview-delete-response .vama-no-btn,
.vama-preview-delete-response .vama-yes-btn {
	border: none;
	border-radius: 0.5rem;
	cursor: pointer;
	font-size: 1.5rem;
	font-weight: 700;
	height: 3.375rem;
	padding: 0.75rem 1rem;
	text-transform: uppercase;
	width: 6.25rem;
}

.vama-preview-delete-response .vama-no-btn {
	background-color: #d4d4d4;
	color: #848484;
}

.vama-preview-delete-response .vama-yes-btn {
	background-color: #ff9f9f;
	color: #fff;
}

.vama-preview-response-deleted {
	align-items: center;
	box-sizing: border-box;
	display: grid;
	font-size: 1.5rem;
	grid-column-gap: 1.5rem;
	grid-template-columns: 3rem 1fr;
	min-height: 163px;
	padding: 1.5rem 2rem;
}

#vama-preview-custom-intro {
	background-color: #feda61;
	border-radius: 1rem;
	box-sizing: border-box;
	font-size: 1.5rem;
	margin: 12% auto -2rem;
	max-width: 725px;
	padding: 2.5% 5% 3rem;
	position: relative;
}

#vama-preview-custom-intro > svg {
	position: absolute;
	top: -3.5rem;
}

#vama-preview-custom-intro h2 {
	font-size: 3rem;
	font-weight: 700;
	line-height: 1.2;
}

#vama-preview-custom-intro h3 {
	font-size: 2rem;
	font-weight: 700;
}

#vama-preview-custom-intro p {
	margin: 1rem 0;
}

#vama-preview-custom-intro a {
	color: #a038e0;
}

#vama-preview-custom-intro ol li {
	font-weight: 700;
	list-style-type: decimal;
	margin: 1rem 0 1rem 2rem;
}

#vama-intro-player-wrap {
	box-sizing: border-box;
	margin: 0 auto;
	max-width: 725px;
	padding: 0 1.5%;
	position: relative;
}

#vama-preview-publish {
	background-color: #fff6ec;
	padding: 5%;
}

#vama-preview-publish > div {
	margin: 0 auto;
	max-width: 675px;
}

#vama-preview-publish h2 {
	font-size: 2.875rem;
	font-weight: 700;
	line-height: 1.2;
}

#vama-preview-publish p {
	font-size: 1.5rem;
	margin: 1.5rem 0;
}

#vama-record-listen-overlay,
.vama-dialog {
	align-items: center;
	background-color: rgba(0,0,0,0.4);
	bottom: 0;
	display: flex;
	justify-content: center;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 11;
}

.vama-dialog > div {
	background-color: #fff;
	border-radius: 1rem;
	box-sizing: border-box;
	margin: 1rem;
	max-width: 575px;
	padding: 2rem 3% 2.5rem;
	text-align: center;
}

#vama-publish-success-overlay svg {
	max-width: 100%;
}

.vama-dialog h2 {
	font-size: 2.875rem;
	font-weight: 700;
}

.vama-dialog svg,
.vama-dialog h2 {
	display: inline-block;
	vertical-align: middle;
}

#vama-error-overlay h2 {
	color: #fd73a4;
	margin-left: 1rem;
}

.vama-dialog p,
.vama-dialog li {
	font-size: 1.5rem;
	margin-top: 1rem;
	text-align: left;
}

#vama-button-help svg {
	height: 32px;
	margin-right: 0.5rem;
	width: 32px;
}

#vama-record-listen-overlay .vama-overlay-close {
	cursor: pointer;
	position: absolute;
	right: 2rem;
	top: 2rem;
}

#vama-record-listen-overlay .vama-overlay-note {
	position: absolute;
	right: 5rem;
	top: -6rem;
}

#vama-record-listen-overlay > div {
	background-color: #c7ff9b;
	border-radius: 1rem;
	box-sizing: border-box;
	font-size: 1.5rem;
	margin: 7rem 1rem;
	max-width: 675px;
	padding: 3rem 2%;
	position: relative;
}

#vama-record-listen-overlay h2 {
	font-size: 2.5rem;
	font-weight: 700;
	line-height: 1.1;
}

#vama-record-listen-overlay p:first-of-type {
	color: #477622;
	font-weight: 700;
}

#vama-record-listen-overlay blockquote {
	color: #496038;
	font-style: italic;
	padding: 0 5.5rem;
	position: relative;
}

#vama-record-listen-overlay blockquote::before {
	content: url(/assets/quotes-left.svg);
	left: 0;
	position: absolute;
	top: 0;
}

#vama-record-listen-overlay blockquote::after {
	bottom: -0.5rem;
	content: url(/assets/quotes-right.svg);
	position: absolute;
	right: 0;
}

#vama-record-listen-overlay .vama-audio-player {
	margin: 2rem auto;
}

#vama-record-response .vama-text-btn {
	margin: 0 auto 1rem;
}

#vama-share-player-wrap,
.vama-home-example-wrap {
	margin: 0 auto 10rem;
	max-width: 910px;
}

.vama-home-example-wrap {
	background-color: #9ee2ff;
	border-radius: 1rem;
	margin-top: 2rem;
}

#vama-share-player-wrap > div:first-of-type,
.vama-home-example-wrap > div {
	margin: 0 6%;
	padding: 4% 0 4rem;
	position: relative;
}

#vama-share-player-wrap > div:first-of-type {
	background-color: #9ee2ff;
	border-radius: 1rem;
	margin: 0 auto;
	z-index: -1;
}

#vama-share-player-wrap > div:first-of-type dl {
	margin: 0 6%;
}

#vama-share-player-wrap dl dt,
.vama-home-example-wrap dl dt {
	font-size: 2.5rem;
	font-weight: 700;
	line-height: 1.1;
}

#vama-share-player-wrap dl dd,
.vama-home-example-wrap dl dd {
	font-size: 1.5625rem;
	line-height: 1.5;
	margin: 0.5rem 0 0;
}

#vama-share-player {
	background-color: #fff;
	border: 0.375rem solid #000;
	border-radius: 1rem;
	margin: -2.5rem 6% 0;
	padding: 1rem 7%;
}

#vama-share-player blockquote,
#vama-share-player #vama-share-intro {
	background-color: #f4e3ff;
	border-radius: 1rem;
	font-size: 1.125rem;
	padding: 2rem 4rem;
	position: relative;
}

#vama-share-player blockquote::before {
	content: url(/assets/quotes-left-sm.svg);
	left: 0;
	position: absolute;
	top: 0;
}

#vama-share-player blockquote::after {
	bottom: -0.5rem;
	content: url(/assets/quotes-right-sm.svg);
	position: absolute;
	right: 0;
}

#vama-share-player #vama-share-intro {
	padding: 1rem;
}

#vama-share-player #vama-share-intro p + p {
	margin-top: 1rem;
}

#vama-share-player h2 {
	font-size: 1.375rem;
	font-weight: 700;
	margin-top: 2rem;
	text-align: center;
}

#vama-share-player h2.active::before {
	content: 'Question from:';
	display: block;
	font-size: 1rem;
	font-weight: normal;
}

#vama-share-player h2:not(.active)::after {
	content: 'or press play to get started';
	display: block;
	font-size: 1rem;
	font-weight: normal;
}

#vama-share-all-questions {
	/* border-bottom: 2px solid #c4c4c4; */
	margin-top: 1rem;
	text-align: center;
}

#vama-share-all-questions h3 {
	font-size: 1.375rem;
	font-weight: 700;
	margin: 1.5rem 0;
	text-align: center;
}

#vama-share-all-questions .vama-info-icon {
	width: 18px;
}

#vama-share-all-questions > div > div:first-of-type {
	border-top: 2px solid #c4c4c4;
	column-gap: 1rem;
	display: grid;
	grid-template-columns: 1.625rem 1fr 0.5fr;
	padding: 0.5rem 0.75rem;
}

#vama-share-all-questions > div.active {
	background-color: #f5f5f5;
}

#vama-share-all-questions > div.active small,
#vama-share-all-questions > div.active dl dt,
#vama-share-all-questions > div.active dl dd {
	color: initial;
}

#vama-share-all-questions > div > div {
	cursor: pointer;
	text-align: left;
}

#vama-share-all-questions > div strong {
	font-size: 1.25rem;
	font-weight: 700;
	line-height: 1.2;
}

#vama-share-all-questions > div small {
	color: #c4c4c4;
	font-size: 1rem;
}

#vama-share-all-questions > div dl {
	text-align: right;
}

#vama-share-all-questions > div dl dt,
#vama-share-all-questions > div dl dd {
	color: #c4c4c4;
	display: inline;
	font-family: Questrial,sans-serif;
	font-size: 1rem;
	line-height: 1.4;
}

#vama-share-all-questions > div dl dt {
	margin-right: 0.25rem;
}

#vama-share-all-questions > div strong,
#vama-share-all-questions > div dl dd::after {
	content: '';
	display: block;
}

#vama-share-all-questions > div p {
	font-size: 1rem;
	padding: 0.5rem 0.75rem 1rem;
	text-align: left;
}

#vama-share-all-questions > div:not(.expanded) p,
#vama-share-all-questions > div:not(.expanded) .vama-secondary-btns,
#vama-share-all-questions > div.active .vama-secondary-btns {
	display: none;
}

#vama-share-all-questions .vama-secondary-btns {
	align-items: center;
	background-color: rgba(204,121,255,0.14);
	display: flex;
	justify-content: center;
	padding: 0.25rem;
}

#vama-share-all-questions .vama-secondary-btns .vama-player-btn {
	flex: 0 0 2.25rem;
	height: 2.25rem;
	margin: 0 10%;
	width: 2.25rem;
}

#vama-share-all-questions .vama-secondary-btns .vama-player-btn img {
	height: 1rem;
	width: 1rem;
}

.vama-example-player-wrap {
	bottom: -7.25rem;
	position: absolute;
	width: 100%;
}

.vama-example-player {
	padding: 1rem 2rem;
}

#vama-share-player .play-pause-btn {
	background-color: #2bbfff;
}

.vama-home-example-wrap.birthday,
.vama-home-example-wrap.birthday .vama-example-player .play-pause-btn,
.vama-home-example-wrap.birthday .vama-example-player .slider .gap-progress,
.vama-home-example-wrap.birthday .vama-example-player .slider .gap-progress .pin {
	background-color: #3d7bf3;
}

.vama-home-example-wrap.convo,
.vama-home-example-wrap.convo .vama-example-player .play-pause-btn,
.vama-home-example-wrap.convo .vama-example-player .slider .gap-progress,
.vama-home-example-wrap.convo .vama-example-player .slider .gap-progress .pin {
	background-color: #ffb526;
}

.vama-example-player + p {
	color: #606060;
	font-size: 1.125rem;
	margin-top: 0.5rem;
}

#vama-share #vama-faq {
	background-color: #d3ffb0;
	padding: 5%;
}

#vama-record #vama-faq {
	background-color: #cefffc;
	padding: 5%;
}

#vama-faq dl {
	margin: 0 auto;
	max-width: 780px;
}

#vama-faq dl dt {
	font-size: 3rem;
	font-weight: 700;
	margin-top: 2rem;
}

#vama-faq dl dd {
	color: #353535;
	font-size: 1.375rem;
}

#vama-faq dl dd p {
	margin: 1.5rem 0;
}

#vama-faq ul li {
	align-items: center;
	background-position: left center;
	background-repeat: no-repeat;
	display: flex;
	margin: 0.5rem 0;
	min-height: 5rem;
	padding-left: 5rem;
}

#vama-faq ul li:first-of-type {
	background-image: url(/assets/faq1.svg);
}

#vama-faq ul li:nth-of-type(2) {
	background-image: url(/assets/faq2.svg);
}

#vama-faq ul li:nth-of-type(3) {
	background-image: url(/assets/faq3.svg);
}

#vama-faq ul li:nth-of-type(4) {
	background-image: url(/assets/faq4.svg);
}

#vama-footer-create {
	background-color: #000;
	color: #fff;
	padding: 5rem 6%;
	text-align: center;
}

#vama-footer-create h2 {
	font-size: 3.125rem;
	font-weight: 700;
	line-height: 1.1;
	margin: 0 auto 2rem;
	max-width: 620px;
}

#vama-create header svg,
#vama-create-faq .vama-create-faq-answer svg,
#vama-preview header svg,
#vama-record-response > div > div svg,
#vama-record-phone svg,
#vama-record-what-next svg,
#vama-preview-custom-intro svg,
#vama-preview-publish svg,
#vama-share header svg,
#vama-faq > div > svg {
	display: none;
}

/* @media screen and (min-width: 1350px) {
	#vama-share-player-wrap {
		margin-left: auto;
		margin-right: auto;
	}
	#vama-share-player-wrap > div {
		margin-left: 6%;
		margin-right: 6%;
	}
} */

/* modified audio player styles */

#vama-share-player .volume, #vama-share-player .download,
.vama-example-player .volume, .vama-example-player .download {
	display: none;
}

@keyframes spin {
  from {
    transform: rotateZ(0); }
  to {
    transform: rotateZ(1turn); } }


  .vama-audio-player.player-accessible .play-pause-btn:hover,
  .vama-audio-player.player-accessible .controls:hover,
  .vama-audio-player.player-accessible .volume__button:hover,
  .vama-audio-player.player-accessible .volume__controls:hover,
  .vama-audio-player.player-accessible .download:hover {
    outline: dotted 1px #999; }
  .vama-audio-player svg,
  .vama-audio-player img {
    display: block; }
  .vama-audio-player .holder {
    position: relative; }
    .vama-audio-player .holder .loading .loading__spinner {
      position: absolute;
      left: calc(50% - 10px);
      top: calc(50% - 10px);
      width: 16px;
      height: 16px;
      border: 2px solid #b0b0b0;
      border-right-color: rgba(0, 0, 0, 0);
      border-radius: 50%;
      animation: spin 0.4s linear infinite; }
    .vama-audio-player .holder .play-pause-btn {
		background-color: #2bbfff;
		border-radius: 50%;
		padding: 1.69rem;
      visibility: hidden;
      cursor: pointer;
      outline: none; }
      .vama-audio-player .holder .play-pause-btn:focus {
        outline: none; }
  .vama-audio-player .slider {
    flex-grow: 1;
    background-color: #c4c4c4;
    cursor: pointer;
    position: relative; }
    .vama-audio-player .slider .gap-progress {
      background-color: #2bbfff;
      border-radius: inherit;
      position: absolute;
      pointer-events: none; }
      .vama-audio-player .slider .gap-progress .pin {
        height: 24px;
        width: 24px;
        border-radius: 12px;
        background-color: #2bbfff;
        position: absolute;
        pointer-events: all; }
        .vama-audio-player .slider .gap-progress .pin::after {
          content: "";
          display: block;
          background: rgba(0, 0, 0, 0);
          width: 200%;
          height: 200%;
          margin-left: -50%;
          margin-top: -50%;
          border-radius: 50%; }
  .vama-audio-player .controls {
    font-family: inherit;
    font-size: 1.5rem;
    line-height: 1;
    color: #000;
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
    margin-left: 24px;
    outline: none; }
    .vama-audio-player .controls .controls__slider {
      margin-left: 30px;
      margin-right: 30px;
      border-radius: 2px;
      height: 4px; }
      .vama-audio-player .controls .controls__slider .controls__progress {
        width: 0;
        height: 100%; }
        .vama-audio-player .controls .controls__slider .controls__progress .progress__pin {
          right: -12px;
          top: -10px; }
    .vama-audio-player .controls span {
      cursor: default;
			font-family: Questrial,sans-serif; }
    .vama-audio-player .controls:focus {
      outline: none; }
  .vama-audio-player .volume {
    position: relative; }
    .vama-audio-player .volume .volume__button {
      cursor: pointer;
      outline: none; }
      .vama-audio-player .volume .volume__button:focus {
        outline: none; }
      .vama-audio-player .volume .volume__button.open path {
        fill: #44bfa3; }
    .vama-audio-player .volume .volume__controls {
      width: 30px;
      height: 135px;
      background-color: rgba(0, 0, 0, 0.62);
      border-radius: 7px;
      position: absolute;
      left: -3px;
      bottom: 52px;
      flex-direction: column;
      align-items: center;
      display: flex;
      z-index: 2;
      outline: none; }
      .vama-audio-player .volume .volume__controls .volume__slider {
        margin-top: 12px;
        margin-bottom: 12px;
        width: 0.375rem;
        border-radius: 3px; }
        .vama-audio-player .volume .volume__controls .volume__slider .volume__progress {
          bottom: 0;
          height: 100%;
          width: 0.375rem; }
          .vama-audio-player .volume .volume__controls .volume__slider .volume__progress .volume__pin {
            left: -5px;
            top: -8px; }
      .vama-audio-player .volume .volume__controls:focus {
        outline: none; }
      .vama-audio-player .volume .volume__controls.hidden {
        display: none; }
      .vama-audio-player .volume .volume__controls.top {
        bottom: 52px;
        left: -3px; }
      .vama-audio-player .volume .volume__controls.middle {
        bottom: -54px;
        left: 54px; }
      .vama-audio-player .volume .volume__controls.bottom {
        bottom: -164px;
        left: -3px; }
  .vama-audio-player .download {
    display: none;
    margin-left: 16px;
    cursor: pointer;
    outline: none; }
    .vama-audio-player .download:focus {
      outline: none; }
  .vama-audio-player:focus {
    outline: none; }

.vama-audio-player .message__offscreen {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal; }

@media screen and (max-width: 980px) {
	.vama-record-tip,
	#vama-eye-tip {
		display: none;
	}
}

@media screen and (min-width: 938px) {
	#vama-create header,
	#vama-preview header,
	#vama-share header {
		align-items: center;
		display: grid;
		margin: 8rem auto 3rem;
		max-width: 830px;
		padding: 1rem;
		text-align: left;
	}
	#vama-create header {
		column-gap: 1rem;
		grid-template-columns: 28% auto;
		margin: 10rem auto 5rem;
	}
	#vama-create header p {
		margin-left: 0;
	}
	#vama-preview header {
		column-gap: 2rem;
		grid-template-columns: 25% auto;
		margin: 10rem auto 1.5rem;
	}
	#vama-preview header > div:not(:first-of-type) {
		grid-column-start: 1;
		grid-column-end: span 2;
	}
	#vama-preview header p {
		padding: 0;
	}
	#vama-share header {
		column-gap: 2rem;
		grid-template-columns: 18% auto;
		margin: 8rem auto 3rem;
	}
	#vama-create header svg,
	#vama-preview header svg,
	#vama-record-response > div > div svg,
	#vama-record-phone svg,
	#vama-record-what-next svg,
	#vama-preview-custom-intro svg,
	#vama-share header svg,
	#vama-faq > div > svg {
		display: inline-block;
		max-width: 100%;
	}
	#vama-record-submitted svg {
		float: right;
	}
	#vama-preview-custom-intro h2 {
		margin-left: 15rem;
	}
	#vama-what-is-vama > div {
		column-gap: 2rem;
		display: grid;
		grid-template-columns: 45% auto;
		max-width: 750px;
	}
	#vama-record-response > div > div:first-of-type {
		column-gap: 2rem;
		display: grid;
		grid-template-columns: 32% auto;
		margin: 3rem auto 4rem;
	}
	#vama-record-response .vama-text-btn {
		margin: 0;
	}
	#vama-record-phone > div {
		column-gap: 2rem;
		display: grid;
		grid-template-columns: 28% auto;
		margin: 0 auto;
	}
	#vama-record-what-next > div {
		align-items: center;
		column-gap: 2rem;
		display: grid;
		grid-template-columns: 30% auto;
		margin: 0 auto;
	}
	#vama-faq {
		padding: 5% 0;
	}
	#vama-faq > div {
		column-gap: 1rem;
		display: grid;
		grid-template-columns: 28% auto;
		margin: 0 auto;
		max-width: 750px;
		overflow: hidden;
	}
	#vama-faq dl {
		margin: 0 5% 0 0;
		max-width: 470px;
	}
}

@media screen and (max-width: 937px) {
	#vama-share-player-wrap,
	.vama-home-example-wrap {
		margin-left: 0.375rem;
		margin-right: 0.375rem;
	}
	.vama-home-example-wrap > div {
		margin: 0 calc(6% - 0.375rem);
	}
	#vama-share-player {
		margin: -2.5rem calc(6% - 0.375rem) 0;
	}
	#vama-faq dl dt:first-of-type {
		margin-top: 0;
	}
}

@media screen and (min-width: 769px) {
	#vama-home header {
		background: #dceffe url(/assets/home-bg-icons.svg) no-repeat center 8.5rem;
		padding: 15rem 5% 10rem;
	}
	#vama-create-faq > div {
		column-gap: 2rem;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		margin: 0 auto;
		max-width: 680px;
	}
	#vama-create-faq h3 {
		color: inherit;
		font-size: 2.5rem;
		font-weight: 700;
		line-height: 1.2;
		margin-bottom: 4rem;
	}
	#vama-create-faq h3.inactive {
		color: #878787;
	}
	#vama-create-faq h3:not(.inactive)::after {
		background-color: #ffb526;
		border-radius: 3rem;
		content: '';
		display: block;
		height: 1rem;
		margin-top: 0.75rem;
		width: 7.5rem;
	}
	#vama-create-faq .vama-create-faq-answer {
		align-items: center;
		column-gap: 2rem;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-column-start: 1;
		grid-column-end: span 2;
		grid-row-start: 2;
	}
	#vama-create-faq .vama-create-faq-answer.inactive {
		display: none;
	}
	#vama-create-faq .vama-create-faq-answer svg,
	#vama-preview-publish svg {
		display: inline-block;
	}
	#vama-record-response h1 {
		min-height: 7.5rem;
	}
	#vama-record-response h1::before,
	#vama-record-response h1::after {
		position: absolute;
		top: -1.1rem;
		z-index: -1;
	}
	#vama-record-response h1::before {
		content: url(/assets/response-left.svg);
		left: -2rem;
	}
	#vama-record-response h1::after {
		content: url(/assets/response-right.svg);
		margin-top: 0.5rem;
		right: -2rem;
	}
	#vama-record-submitted > div {
		align-items: center;
		column-gap: 2rem;
		display: grid;
		grid-template-columns: 32% auto;
		margin: 3rem auto 5rem;
		text-align: inherit;
	}
	#vama-record-submitted h2 {
		font-size: 3rem;
		font-weight: 700;
		line-height: 1.2;
		white-space: nowrap;
	}
	#vama-preview header {
		margin-bottom: 4rem;
	}
	#vama-preview header p {
		font-size: 2.5rem;
		padding-bottom: 1rem;
	}
	#vama-home-how-works {
		padding-bottom: 1rem;
	}
	#vama-home-how-works ul {
		display: grid;
		grid-column-gap: 3.5rem;
		grid-template-columns: repeat(5, 1fr);
		margin: 5% auto;
		max-width: 910px;
	}
	#vama-preview-main ul {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		margin: calc(1px - 5% - 4rem) auto 6%;
		max-width: 720px;
	}
	#vama-record-response ol {
		display: grid;
		grid-column-gap: 3.5rem;
		grid-template-columns: repeat(3, 1fr);
		margin: 6% auto;
		max-width: 480px;
	}
	#vama-home-how-works ul li,
	#vama-preview-main ul li,
	#vama-record-response ol li {
		align-items: flex-start;
		background-position: center top;
		background-size: contain;
		justify-content: center;
		padding: 115% 0 0;
		text-align: center;
	}
	#vama-preview-main ul li {
		padding: 10rem 0.75rem 0;
	}
	#vama-preview-publish > div:first-of-type {
		display: grid;
		grid-template-columns: 45% 1fr;
	}
	#vama-preview-publish p:last-of-type {
		margin-bottom: 8rem;
	}
}

@media screen and (min-width: 650px) {
	#vama-what-is-vama {
		background: #dceffe url(/assets/home-bg-clouds.svg) no-repeat center 3.5rem;
		padding: 5rem 5% 20rem;
	}
	#vama-about {
		background: #dceffe url(/assets/home-bg-clouds.svg) no-repeat center 6.5rem;
	}
	#vama-about #vama-what-is-vama {
		background: none;
		padding: 8.5rem 5% 4rem;
	}
	#vama-about-text {
		padding: 0 5% 5%;
	}
	#vama-what-is-vama > div {
		column-gap: 2rem;
		display: grid;
		grid-template-columns: 45% auto;
		max-width: 750px;
	}
	#vama-what-is-vama svg {
		display: inline-block;
		margin: 0;
		max-width: 100%;
	}
	#vama-what-is-vama dl dt {
		margin-top: 2rem;
	}
	.vama-preview-delete-response {
		align-items: center;
		display: grid;
		grid-column-gap: 0.5rem;
		grid-template-columns: 1fr 6.25rem 6.25rem;
		padding: 1.5rem 2rem;
	}
	#vama-share-player-wrap dl > div {
		display: flex;
	}
	#vama-share-player .vama-audio-player.fixed {
		display: none;
	}
}

@media screen and (max-width: 649px) {
	nav .vama-logo-txt {
		display: none;
	}
	nav .vama-nav-btn {
		padding: 1rem;
	}
	nav .vama-nav-link,
	footer p {
		font-size: 1rem;
	}
	nav .vama-nav-btn,
	#vama-footer-create .vama-create-btn {
		font-size: 0.75rem;
	}
	#vama-create header,
	#vama-preview header,
	#vama-share header {
		padding: 6.5rem 5% 2rem;
	}
	#vama-create header h1,
	#vama-preview header h1,
	#vama-record-response h1,
	#vama-share header h1,
	#vama-home-how-works h2,
	#vama-home-examples h2 {
		font-size: 2.5rem;
	}
	#vama-what-is-vama dt,
	#vama-home-ways-use h2,
	#vama-create-form h2,
	#vama-create-details h2,
	#vama-create-faq h3,
	#vama-preview-responses h2,
	#vama-preview-custom-intro h2,
	#vama-preview-publish h2,
	#vama-record-response h2,
	#vama-record-phone h2,
	#vama-record-what-next h2,
	#vama-record-delete-overlay h2,
	#vama-record-submitted h3,
	#vama-share-player-wrap dl dt,
	.vama-home-example-wrap dl dt,
	#vama-footer-create h2,
	#vama-faq dl dt {
		font-size: 2rem;
	}
	#vama-create-form label,
	#vama-create-details label,
	#vama-create-form form button,
	#vama-create-details button,
	#vama-preview-main .vama-submit-btn,
	#vama-preview-no-responses h3,
	#vama-preview-custom-intro h3,
	#vama-preview-publish button,
	#vama-record-response .vama-submit-btn,
	.vama-confirm-btn,
	.vama-cancel-link {
		font-size: 1.5rem;
	}
	#vama-about-text > *,
	#vama-what-is-vama dd,
	#vama-create header p,
	#vama-create-faq p,
	#vama-create-details ul,
	#vama-preview-no-responses p,
	#vama-preview-all-responses dl:first-of-type,
	.vama-preview-response-deleted,
	#vama-preview-custom-intro p,
	#vama-preview-custom-intro ol li,
	#vama-preview-publish p,
	#vama-record-response > div > div p,
	#vama-record-response p,
	#vama-record-phone p,
	#vama-record-what-next p,
	#vama-record-delete-overlay p,
	#vama-all-submitted-questions .vama-question-playback dl,
	dl.vama-transcription,
	.vama-question-playback + dl,
	dl.vama-transcription dd,
	.vama-question-playback + dl dd,
	.vama-question-playback ul li,
	#vama-error-overlay p,
	#vama-share-player-wrap dl dd,
	.vama-home-example-wrap dl dd,
	#vama-faq dl dd,
	.vama-audio-player .vama-current-time,
	.vama-audio-player .vama-total-time {
		font-size: 1.25rem;
	}
	#vama-create-form form input,
	#vama-create-form form textarea,
	#vama-create-details textarea,
	#vama-share-all-questions dl dt,
	#vama-share-all-questions dl dd,
	.vama-record-warning {
		font-size: 1rem;
	}
	#vama-about #vama-what-is-vama {
		padding-top: 6.5rem;
	}
	#vama-about-text {
		background-color: #dceffe;
		padding: 5%;
	}
	#vama-faq ul li {
		background-size: 45px;
		padding-left: 4rem;
	}
	#vama-preview-no-responses {
		margin: 10% 15%;
	}
	#vama-share-player-wrap {
		margin-bottom: 6rem;
	}
	#vama-share-player {
		bottom: -3rem;
	}
	.vama-audio-player {
		padding: 0.75rem 1rem;
	}
	.vama-audio-player .holder .play-pause-btn {
		padding: 1.25rem;
	}
	.vama-audio-player .play-pause-btn__icon {
		height: 1.25rem;
		width: 1.25rem;
	}
	.vama-audio-player .controls {
		font-size: 1rem;
	}
	#vama-create .vama-player-btn,
	#vama-preview .vama-player-btn,
	#vama-record .vama-player-btn,
	#vama-share .vama-player-btn {
		flex: 0 0 3rem;
		height: 3rem;
		width: 3rem;
	}
	#vama-create-player .vama-record-btn,
	#vama-intro-player .vama-record-btn {
		margin-right: 0;
	}
	.vama-audio-player .vama-player-slider {
		margin: 0 1.5rem;
	}
	#vama-create-player .vama-current-time,
	#vama-intro-player .vama-current-time {
		display: none;
	}
	#vama-footer-create {
		padding: 2.5rem 6%;
	}
	#vama-footer-create .vama-create-btn {
		padding: 1rem;
	}
	.vama-preview-delete-response {
		padding: 1rem;
		text-align: center;
	}
	.vama-preview-delete-response .vama-no-btn,
	.vama-preview-delete-response .vama-yes-btn {
		font-size: 1.25rem;
		margin: 1rem 0.5rem 0;
	}
	#vama-preview-all-responses dl:first-of-type,
	#vama-preview-all-responses dl.vama-transcription {
		padding: 1rem;
	}
	#vama-preview-all-responses .vama-submit-btn {
		margin: 1rem;
		width: calc(100% - 2rem);
	}
	#vama-record-listen-overlay > div {
		padding: 4rem 1rem 1rem;
	}
	#vama-record-listen-overlay .vama-audio-player {
		margin-bottom: 1rem;
	}
	#vama-record-listen-overlay blockquote {
		font-size: 1.25rem;
		padding: 0;
	}
	#vama-share-player blockquote {
		font-size: 1rem;
		padding: 0.75rem;
	}
	#vama-record-listen-overlay blockquote::before,
	#vama-record-listen-overlay blockquote::after,
	#vama-share-player blockquote::before,
	#vama-share-player blockquote::after {
		display: none;
	}
	#vama-record-response {
		margin-top: 4.75rem;
		padding: 5%;
	}
	#vama-home header {
		padding: 7rem 5% 0;
	}
	#vama-what-is-vama {
		text-align: center;
	}
}

@media screen and (max-width: 549px) {
	#vama-home-ways-use > div {
		grid-template-columns: repeat(2,1fr);
	}
	#vama-home-ways-use dl dd {
		font-size: 1rem;
		margin: 0.5rem 0;
	}
	.vama-dialog h2 {
		font-size: 2rem;
	}
	.vama-dialog p,
	.vama-dialog li {
		font-size: 1rem;
	}
}

@media screen and (max-width: 539px) {
	.vama-question-playback {
		grid-template-columns: 1fr 3.375rem;
		padding: 2rem 1rem 1.5rem;
	}
	.vama-question-playback > div {
		grid-column: 1 / 3;
		margin-bottom: 1.5rem;
		order: 1;
	}
	#vama-record .vama-question-playback .vama-player-btn {
		order: 2;
	}
	.vama-question-playback .vama-heart-btn {
		order: 3;
	}
	dl.vama-transcription,
	.vama-question-playback + dl {
		padding: 0 1rem 1.5rem;
	}
	#vama-share-player {
		padding: 0.75rem;
	}
	#vama-share-player blockquote,
	#vama-share-all-questions > div p {
		font-size: 0.875rem;
	}
	#vama-share-all-questions > div > div:first-of-type {
		grid-template-columns: 1.625rem 1fr;
	}
	#vama-share-all-questions > div dl {
		grid-column: 1 / 3;
	}
	#vama-share-all-questions > div dl dt,
	#vama-share-all-questions > div dl dd {
		display: inline-block;
	}
	#vama-share-all-questions > div dl dt {
		margin-left: 0.5rem;
	}
	#vama-preview-all-responses .vama-download-btn,
	#vama-preview-all-responses .vama-copy-btn {
		display: none;
	}
}

@media screen and (max-width: 399px) {
	nav .vama-nav-link {
		margin-right: 1rem;
	}
	#vama-record-listen-overlay h2 {
		font-size: 2rem;
	}
	#vama-submitted-questions h2,
	#vama-preview-responses h2 {
		font-size: 1.75rem;
	}
	#vama-record-listen-overlay > div {
		font-size: 1.25rem;
	}
	#vama-record header p {
		font-size: 1rem;
	}
	#vama-publish-success-overlay svg,
	#vama-record-listen-overlay blockquote {
		display: none;
	}
}

@media screen and (max-width: 320px) {
	.vama-audio-player .controls .controls__current-time,
	.vama-audio-player .controls .controls__total-time,
	#vama-record-listen-overlay .vama-current-time,
	#vama-preview-all-responses .vama-player-slider {
		display: none;
	}
	#vama-intro-player-wrap .vama-player-slider {
		visibility: hidden;
	}
	.vama-audio-player .controls .controls__slider {
		margin: 0;
	}
	#vama-preview-all-responses .vama-respond-btn,
	#vama-preview-all-responses .vama-play-btn {
		margin-left: 1rem;
	}
}

/* aaron added */
#vama-create-faq h3.inactive {
  cursor: pointer;
}

#vama-share-all-questions > div.playing {
	background-color: #f5f5f5;
}

#vama-create-faq h3.inactive:hover {
  text-decoration: underline;
}

.answerer_details.inactive {
  display: none;
}

.answerer_details {
  display: block;
}

.vama-info-icon {
  cursor: pointer;
}

.hidden {
  visibility: hidden;
}

.vama-question-playback .vama-heart-btn.liked,
#vama-share-player .vama-heart-btn.liked {
	cursor: auto;
}

.tags-container {
	position: relative;
}
.ReactTags__tags {
  display: flex;
  position: relative;
  top: -92px;
  left: 12px;	
}

/* Styles for the input */
.ReactTags__tagInput {
  width: 300px;
  border-radius: 2px;
  display: inline-block;
}
.ReactTags__tagInput input.ReactTags__tagInputField,
.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  height: 31px;
  margin: 0;
  font-size: 12px;
  width: 100%;
  border: none !important;
  outline: none !important;
  box-sizing: border-box !important;
}

/* Styles for selected tags */
.ReactTags__selected span.ReactTags__tag {
  border: 1px solid #ddd;
  background: #abea7b;
  color: white;
  font-size: 20px;
  display: inline-block;
  padding: 10px;
  margin: 0 5px;
  border-radius: 10px;
}
.ReactTags__selected a.ReactTags__remove {
  color: #aaa;
  margin-left: 5px;
  cursor: pointer;
}

@media screen and (max-width: 539px) {
	.ReactTags__tags {
		top: -85px;
		left: 8px;	
	}
	.ReactTags__tagInput {
		width: 123px;
	}
	.ReactTags__selected span.ReactTags__tag {
		font-size: 16px;
		padding: 7px;
	  }
}

/* Styles for suggestions */
.ReactTags__suggestions {
  position: absolute;
}
.ReactTags__suggestions ul {
  list-style-type: none;
  box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
  background: white;
  width: 200px;
}
.ReactTags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 5px 10px;
  margin: 0;
}
.ReactTags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}
.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
  background: #b7cfe0;
  cursor: pointer;
}

.ReactTags__remove {
  border: none;
  cursor: pointer;
  background: none;
  color: white;
  padding-left: 11px;
  padding-right: 3px;
}

dl.vama-transcription a {
	margin-left: auto;
}

.vama-text-question {
	padding: 10px;
	line-height: 22px;
}
.vama-question-label {
	font-size: 1.25rem;
}